import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import LegacyModernizationContainer from "../containers/LegacyModernizationContainer"

const LegacyModernization = (props) => (
  <Layout
    pageInfo={{ pageName: "Legacy Modernization" }}
    styleClass="home-page"
    location={'/legacymodernization/'}>
    <SEO title="Legacy Modernization" />
    <LegacyModernizationContainer />
  </Layout>
)

export default LegacyModernization
