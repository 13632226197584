import React from 'react'
import { Col, Row, Container } from 'react-bootstrap'
import coreValueImage from '../../images/slicing/service/corevalue.png'
import { legacyCoreValues } from '../../staticdata/legacyModernization'
const serviceValueStyle = {
    border: 'solid 1px #e5e4e4',
    backgroundColor: '#ffffff'
}
const WhyGtenLegacyModern = () => {
    return (
        <Container fluid className="mt-5">
            <Container fluid>
                <Row className="d-md-none d-lg-none d-xl-none p-2 w-100">
                    <Col className="services-section-right">
                    <div className="d-flex justify-content-start align-items-center">
                            <div className="line" style={{marginTop:'0px', marginRight:'2px'}}></div>
                            <div>
                                <h6 className="carousel-section-subheading" style={{color:'#eebb6b'}}>Why GTEN Technologies?</h6>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="p-5" style={{width:'100%'}}>
                    <Col className="carousel-columns-images core-value-mobile">
                        <img
                            className="d-block w-100"
                            src={coreValueImage}
                            alt="Not Found"
                        />
                    </Col>
                    <Col className="carousel-columns" md="7" >
                        <div className="d-none d-md-flex d-lg-flex d-xl-flex justify-content-start align-items-center">
                            <div className="line"></div>
                            <div>
                                <h6 className="carousel-section-subheading">Why GTEN Technologies?</h6>
                            </div>
                        </div>
                        {
                            legacyCoreValues.map((coreValue, index) => (
                                <Row className="p-3 core-value-item" key={index + 'row'}>
                                    {coreValue.map((valuesRow, rowIndex) => (
                                        <Col key={rowIndex + 'corevalue'} sm="12" xs="12" md="6" className="core-value-col">
                                            <Row className={`p-2 ${rowIndex === 1 && 'ml-md-2'}`} style={serviceValueStyle}>
                                                <Col md="4" xs="4" sm="4">
                                                    <img src={valuesRow.imageSrc} className="corevalue-image" width="75px" height="75px" alt="Not Found" />
                                                </Col>
                                                <Col className="pl-1 d-none d-md-flex d-xl-flex d-lg-flex align-items-center ">
                                                    <p>{valuesRow.heading}</p>
                                                </Col>
                                                <Col className="pl-1 d-flex align-items-center d-md-none d-lg-none" xs="8" sm="8">
                                                    <p>{valuesRow.heading}</p>
                                                </Col>
                                            </Row>
                                        </Col>
                                    ))}
                                </Row>
                            ))
                        }

                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default WhyGtenLegacyModern;