import React, { Component } from 'react'
import BannerImage from '../components/elements/BannerImage';
import banner from '../images/slicing/service/banner.png'
import WhyGtenLegacyModern from '../components/services/WhyGtenLegacyModern';
import CaseStudyComponent from '../components/services/CaseStudyComponent';
import TestimonialComponent from '../components/services/TestimonialComponent'
import { firstPara, offeringsLegacy } from '../staticdata/legacyModernization'
import { Col, Container, Row } from 'react-bootstrap';
import ServicesNewComponent from '../components/services/ServicesNewComponent';
class LegacyModernizationContainery extends Component {
    render() {
        return (
            <>
                <BannerImage
                    activeTab="Legacy Modernization"
                    bannerImage={banner}
                    text="Drive innovation, improve customer experiences and stay competitive by leverage tech to modernize your platforms." />
                <Container className=" pt-3 pb-5">
                    {firstPara && firstPara.map((para, index) => (
                        <Row className="pt-2 px-3" key={`para_${index}`}>
                            <Col>
                                <p className="initial-paragrapgh">
                                    {para}
                                </p>
                            </Col>
                        </Row>
                    ))}
                </Container>
                <ServicesNewComponent
                    heading="Offerings & solutions"
                    height="350px"
                    bgColor='#ffffff'
                    page="services"
                    data={offeringsLegacy} />
                <WhyGtenLegacyModern />
                <hr />
                <CaseStudyComponent />
                <TestimonialComponent />
            </>
        );
    }
}

export default LegacyModernizationContainery;